import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as garmentStyles from "../css/garment.module.css"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Bggarment from "../components/bggarment"
import { rhythm } from '../utils/typography'
import Seo from "../components/seo"

const Seotitle = "งานการ์เม้นท์"
const SeoDecription = "งานการ์เม้นท์ เกี่ยวกับเส้นด้าย ที่เป็นแรงบันดาลใจ ในการให้บริการ เราเป็นผู้ผลิตรายใหญ่และด้วยประสบการณ์ในวงการสิ่งทอที่ยาวนาน 60 ปี"

export default class GarmentCatagories extends React.Component {
    render() {
      const posts = this.props.data.allMarkdownRemark.edges
      const { garmentcurrentPage, garmentnumPages } = this.props.pageContext
      const isFirst = garmentcurrentPage === 1
      const isLast = garmentcurrentPage === garmentnumPages
      const prevPage = garmentcurrentPage - 1 === 1 ? "/garment/" : "/garment/" + (garmentcurrentPage - 1).toString()
      const nextPage = "/garment/" + (garmentcurrentPage + 1).toString()
      const url = "/garment/"

    return (
      <Containers>
        <Seo title={Seotitle} description={SeoDecription} pathname={url} />
        <Headertop />
        <Header />
        <Bggarment />
        <div className={garmentStyles.herob}>
          <Container fluid="xl">
            <Row>
              <Col>
                <p>
                  <Link to="/">Home</Link> <Icon icon={chevronDoubleRight} />{" "}
                  Product
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={garmentStyles.herosection}>
          <Container fluid="xl">
            <Row>
              <Col md={3}>
                <div className={garmentStyles.categories}>
                  <h3>หมวดหมู่สินค้า</h3>
                  <Link to="/categories/">
                    <p className={garmentStyles.pmenu}>สินค้าทั้งหมด</p>
                  </Link>
                  <Link to="/garment/">
                    <p className={garmentStyles.pmenuactive}>งานการ์เม้นท์</p>
                  </Link>
                  <Link to="/sew-a-sack/">
                    <p className={garmentStyles.pmenu}>ด้ายเย็บกระสอบ</p>
                  </Link>
                  <Link to="/industrial-thread/">
                    <p className={garmentStyles.pmenu}>ด้ายอุตสหกรรม</p>
                  </Link>
                  <Link to="/product-import/">
                    <p className={garmentStyles.pmenu}>สินค้านำเข้า</p>
                  </Link>
                  <Link to="/other-products/">
                    <p className={garmentStyles.pmenu}>สินค้าอื่นๆ</p>
                  </Link>
                </div>
              </Col>
              <Col md={9}>
                <Containers>
                  <Row>
                    {posts.map(({ node }) => {
                      const title = node.frontmatter.title || node.fields.slug
                      const link = node.fields.slug
                      const image =
                        node.frontmatter.featuredImage.childImageSharp.fluid
                      return (
                        <Col
                          xs={6}
                          md={4}
                          key={node.id}
                          className={homeStyles.colproduct}
                        >
                          <Link to={link}>
                            <Img
                              fluid={image}
                              className={homeStyles.imgproduct}
                            />
                            <h3>{title}</h3>
                          </Link>
                        </Col>
                      )
                    })}
                  </Row>
                </Containers>
                <Containers>
                  <Row>
                    <Col>
                      <ul
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          listStyle: "none",
                          paddingTop: 30,
                          marginLeft: 0,
                          justifyContent: "center",
                        }}
                      >
                        {!isFirst && (
                          <Link to={prevPage} rel="prev">
                            ← Previous
                          </Link>
                        )}
                        {Array.from({ length: garmentnumPages }, (_, i) => (
                          <li
                            key={`pagination-number${i + 1}`}
                            style={{
                              margin: 0,
                            }}
                          >
                            <Link
                              to={`/garment/${i === 0 ? "" : i + 1}`}
                              style={{
                                padding: rhythm(1 / 4),
                                paddingLeft: 20,
                                paddingRight: 20,
                                borderRadius: 6,
                                textDecoration: "none",
                                color:
                                  i + 1 === garmentcurrentPage ? "#ffffff" : "",
                                background:
                                  i + 1 === garmentcurrentPage ? "#050248" : "",
                              }}
                            >
                              {i + 1}
                            </Link>
                          </li>
                        ))}
                        {!isLast && (
                          <Link to={nextPage} rel="next">
                            Next →
                          </Link>
                        )}
                      </ul>
                    </Col>
                  </Row>
                </Containers>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="pb-5">
          <Row>
            <Col md={12}>
              <p>
                งานการ์เมนท์ (Garment)
                หรือที่เรียกกันอีกชื่อหนึ่งว่างานเสื้อผ้าคือกิจกรรมที่เกี่ยวข้องกับอุตสาหกรรมสิ่งทอและอุตสาหกรรมเสื้อผ้า
                ซึ่งรวมถึงการออกแบบเสื้อผ้า การผลิตเสื้อผ้า การจัดจำหน่าย
                การตลาด และกิจกรรมที่เกี่ยวข้องอื่น ๆ
                ที่เกี่ยวข้องกับสายงานสิ่งทอและเสื้อผ้าทั้งหมด
              </p>
              <h2>งานการ์เมนท์ มีกระบวนการที่ซับซ้อน</h2>
              <p>
                ซับซ้อนตั้งแต่การออกแบบเสื้อผ้า
                การเลือกวัตถุดิบ การตัดและเย็บเสื้อผ้า การตรวจสอบคุณภาพ
                การบรรจุหีบห่อ และการจัดจำหน่าย รวมถึงการทำการตลาด เช่น
                การวิจัยตลาด เรียนรู้และปรับปรุงสินค้าตามความต้องการของตลาด
                เพื่อให้ผู้บริโภคสนใจและเลือกซื้อสินค้าเสื้อผ้าที่มีอยู่ในตลาด
              </p>
              <p>
                งานการ์เมนท์เป็นอุตสาหกรรมที่มีบทบาทสำคัญในเศรษฐกิจของหลายประเทศ
                มีการผลิตและส่งออกเสื้อผ้าไปยังตลาดระหว่างประเทศ
                นอกจากนี้ยังมีการสร้างอาชีพและเป็นแหล่งรายได้สำคัญสำหรับผู้ประกอบการและคนงานในอุตสาหกรรมสิ่งทอและเสื้อผ้า
              </p>
              <p>
                เราบริษัท ทรงไทยเท็กซ็ไทล์ เป็น 1 ในอุตสาหกรรมสิ่งทอ
                นึกถึงเส้นด้าย ต้องนึกถึงเรา
                และเรามีความภาคภูมิใจในการขับเคลื่อนที่มีผลวัต
                มีทีมผู้บริหารที่มีคุณภาพและมีทักษะสูงที่มีความรู้เชิงลึกในทุกแง่มุมของอุตสาหกรรมธุรกิจสิ่งทอ
                ช่วยต่อยอดและเป็นต้นน้ำให้กับธุรกิจนี้มาอย่างยาวนานกว่า 60 ปี
              </p>
            </Col>
          </Row>
        </Container>
        <Formfooter />
        <Footer />
      </Containers>
    )
 }
}

export const GarmentCatagoriesQuery = graphql`
  query GarmentCatagoriesQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "garment" }}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`